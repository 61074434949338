import { awsmobile as env } from '../aws-exports'
import axios from 'axios'
import qs from 'qs'
import Cookies, { CookieAttributes } from 'js-cookie'
/**
 * my-lixil token return from exchange api
 */
export interface MylixilToken {
  id_token: string
  id_token_expires: string
  access_token: string
  access_token_expires: string
  refresh_token: string
  refresh_token_expires?: string
}
/**
 * Authentication params need to make request to my-lixil
 */
export enum AuthCookies {
  STATE = 'janrain_state',
  NONCE = 'janrain_nonce',
  CODE_VERIFIER = 'janrain_code_verifier',
  CODE_CHALLENGE = 'janrain_code_challenge',
  JANRAIN_TOKEN = 'janrain_token',
}

/**
 * Cookie management interface to set, get, remove cookies
 */
export interface IGetCookies {
  getItem: (item: AuthCookies) => string
  setItem: (item: AuthCookies, value: string, option?: CookieAttributes) => void
  getJsonToken: (item: AuthCookies) => MylixilToken
  getJson: (item: AuthCookies) => any
  remove: (item: AuthCookies) => void
}
export class GetCookies implements IGetCookies {
  /**
   * return class instance
   */
  public static getInstance(): GetCookies {
    if (!GetCookies.instance) {
      GetCookies.instance = new GetCookies()
    }

    return GetCookies.instance
  }
  private static instance: GetCookies

  /**
   * get cookie value
   * @param item cookie name
   * @return cookie value corresponding to item key
   */
  public getItem(item: AuthCookies) {
    return Cookies.get(item) as string
  }
  /**
   * set cookie to browser
   * @param item cookie key
   * @param value cookie value
   * @param option cookie attributes
   */
  public setItem(item: AuthCookies, value: string, option?: CookieAttributes) {
    Cookies.set(item, value, {
      ...option,
      secure: true,
      sameSite: 'strict',
    })
  }
  /**
   * get my-lixil token store in cookie
   */
  public getJsonToken() {
    return Cookies.getJSON(AuthCookies.JANRAIN_TOKEN) as MylixilToken
  }
  /**
   * get cookie and parse to json object
   */
  public getJson() {
    return Cookies.getJSON(AuthCookies.JANRAIN_TOKEN)
  }
  /**
   * remove cookie from cookie storage
   * @param item cookie key
   */
  public remove(item: AuthCookies) {
    Cookies.remove(item)
  }
}
export class Generator {
  /**
   *
   * @param length
   * random string- use to generate nonce and state default 22 characters length
   */
  public static randomString(length = 22) {
    let text = ''
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length))
    }
    return text
  }
  /**
   * convert base 10 to base 16
   * @param dec number
   */
  public static dec2hex(dec: number) {
    return ('0' + dec.toString(16)).substr(-2)
  }
  /**
   * generate code verifier
   * this code use in exchange api and to generate code challange
   */
  public static generateCodeVerifier() {
    const array = new Uint32Array(56)
    window.crypto.getRandomValues(array)
    return Array.from(array, Generator.dec2hex).join('')
  }
  /**
   *
   * @param plain : string
   * encode using sha256 algorithms
   */
  public static async sha256(plain: string): Promise<ArrayBuffer> {
    // returns promise ArrayBuffer
    const encoder = new TextEncoder()
    const data = encoder.encode(plain)
    return await window.crypto.subtle.digest('SHA-256', data)
  }
  /**
   *
   * @param hashed hash number
   * exchange codeVerifier to code challenger
   */
  public static base64urlencode(hashed: ArrayBuffer) {
    let str = ''
    const bytes = new Uint8Array(hashed)
    const len = bytes.byteLength
    for (let i = 0; i < len; i++) {
      str += String.fromCharCode(bytes[i])
    }
    return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
  }
  /**
   *
   * @param codeVerifier code verifier generated from generateCodeVerifier mathod
   * generate code challenge
   */
  public static async generateCodeChallengeFromVerifier(codeVerifier: string) {
    const hashed = await Generator.sha256(codeVerifier)
    const base64encoded = Generator.base64urlencode(hashed)
    return base64encoded
  }
}

const Cookie = GetCookies.getInstance()
class Authentication {
  public redirectUri = `${window.location.origin}/callback`
  public claims =
    '%7B%22userinfo%22%3A%7B%22family_name%22%3Anull%2C%22given_name%22%3Anull%2C%22family_name_kana%22%3Anull%2C%22given_name_kana%22%3Anull%2C%22gender%22%3Anull%2C%22business%22%3Anull%7D%2C%22id_token%22%3A%7B%22family_name%22%3Anull%2C%22given_name%22%3Anull%2C%22family_name_kana%22%3Anull%2C%22given_name_kana%22%3Anull%2C%22gender%22%3Anull%2C%22business%22%3Anull%7D%7D'
  public janrainUrlBase = `https://${env.MY_LIXIL_API_DOMAIN}/ja/v1/${env.JANRAIN_CLIENT_ID}`
  private state = ''
  private nonce = ''
  private codeVerifier = ''
  private codeChallenge = ''
  private errorMsg = ''
  constructor() {
    this.init()
  }

  private static refreshCount = 0
  /**
   * init class required attributes
   */
  public async init() {
    if (
      !Cookie.getItem(AuthCookies.STATE) ||
      !Cookie.getItem(AuthCookies.NONCE) ||
      !Cookie.getItem(AuthCookies.CODE_VERIFIER) ||
      !Cookie.getItem(AuthCookies.CODE_CHALLENGE)
    ) {
      this.state = Generator.randomString()
      this.nonce = Generator.randomString()
      this.codeVerifier = Generator.generateCodeVerifier()
      this.codeChallenge = await Generator.generateCodeChallengeFromVerifier(this.codeVerifier)
      Cookie.setItem(AuthCookies.STATE, this.state)
      Cookie.setItem(AuthCookies.NONCE, this.nonce)
      Cookie.setItem(AuthCookies.CODE_VERIFIER, this.codeVerifier)
      Cookie.setItem(AuthCookies.CODE_CHALLENGE, this.codeChallenge)
    } else {
      this.state = Cookie.getItem(AuthCookies.STATE)
      this.nonce = Cookie.getItem(AuthCookies.NONCE)
      this.codeVerifier = Cookie.getItem(AuthCookies.CODE_VERIFIER)
      this.codeChallenge = Cookie.getItem(AuthCookies.CODE_CHALLENGE)
    }
  }
  /**
   * login to mylixil
   * this method will assign user to mylixil login page
   */
  public async login() {
    const url = `${this.janrainUrlBase}/login?return_url=${this.redirectUri}&state=${this.state}&nonce=${this.nonce}&code_challenge=${this.codeChallenge}&&claims=${this.claims}`
    window.location.assign(url)
  }

  public async updateprofile() {
    const url = `https://${env.MY_LIXIL_SCREEN_DOMAIN}/ja/${env.JANRAIN_CLIENT_ID}/service`
    window.location.assign(url)
  }
  /**
   * exchange token required after login
   * @param code code returned from callback url after login success
   */
  public async exchange(code: string) {
    const data = qs.stringify({
      code,
      code_verifier: this.codeVerifier,
    })
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    }
    const url = `${this.janrainUrlBase}/exchange`
    const res = await axios.post(url, data, { headers })
    Cookie.setItem(AuthCookies.JANRAIN_TOKEN, JSON.stringify(res.data))
    return res.data
  }
  /**
   * check if additional process is needed
   * now mylixil redirect incorrect
   */
  public async checkAdditionalProcess() {
    const idToken = Cookie.getJsonToken().id_token
    const url = `${this.janrainUrlBase}/additional_process`
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
    }
    try {
      const { status } = await axios.get(url, { headers })
      if (status === 200) return true
      return false
    } catch (err) {
      return false
    }
  }
  /**
   * assign user to additional_process page
   */
  public performAdditionalProcess() {
    const url = `https://${env.MY_LIXIL_SCREEN_DOMAIN}/ja/${env.JANRAIN_CLIENT_ID}/forms?return_url=${this.redirectUri}`
    window.location.assign(url)
  }
  /**
   * refresh token needed when access_token and id_token expired
   */
  public async refesh() {
    const { id_token, refresh_token } = Cookie.getJsonToken()
    const data = qs.stringify({
      id_token,
      refresh_token,
    })
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    }
    const url = `${this.janrainUrlBase}/refresh`
    try {
      const res = await axios.post(url, data, { headers })
      const token: MylixilToken = res.data
      Cookie.setItem(AuthCookies.JANRAIN_TOKEN, JSON.stringify(token))
      Authentication.refreshCount = 0
      return token
    } catch (err) {
      Authentication.refreshCount += 1
      if (Authentication.refreshCount > 5) {
        Authentication.refreshCount = 0
        this.logout()
      }
    }
  }
  /**
   * check if user is authen
   */
  public isAuthenticated(): boolean {
    try {
      const { id_token, refresh_token } = Cookie.getJsonToken()
      if (!id_token || !refresh_token) return false
      return true
    } catch (err) {
      return false
    }
  }
  /**
   * logout from my-lixil
   * this method only log user out from mylixil not this page
   */
  public async logout() {
    Object.values(AuthCookies).forEach((e: AuthCookies) => Cookie.remove(e))
    const url = `https://${env.MY_LIXIL_SCREEN_DOMAIN}/ja/logout?return_url=${this.redirectUri}`
    window.location.assign(url)
  }
  public removeCookie() {
    // Object.values(AuthCookies).forEach((e: AuthCookies) => Cookie.remove(e));
    Cookie.remove(AuthCookies.JANRAIN_TOKEN)
  }
  public getErrorMsg() {
    return this.errorMsg
  }

  public setErrorMsg(msg = '') {
    this.errorMsg = msg
  }
  public async getUserInfo() {
    const url = `https://${env.JANRAIN_DOMAIN}/${env.JANRAIN_CUSTOMER_ID}/profiles/oidc/userinfo`
    const { id_token } = Cookie.getJsonToken()
    const options = {
      headers: {
        Authorization: `Bearer ${id_token}`,
      },
    }
    const res = await axios.get(url, options)
    return res.data
  }

  public async checkPermission(userInfo: any) {
    if (!userInfo) {
      throw new Error('USER_NOT_FOUND')
    }

    const BLOCK_USER_LEVEL = ['A1', 'B', 'C']

    if (BLOCK_USER_LEVEL.includes(userInfo)) {
      throw new Error('ACCESS_DENIED')
    }
  }
}

export const Auth = new Authentication()
