import ApiRequestBase from './api_request_base'
import {
  AccountItem,
  AccountUpdateWithUpdatedTime,
  AccountCreate,
  AccountCreateResponse,
} from '@/models/account_item'
import { AxiosResponse } from 'axios'
export class AccountApi extends ApiRequestBase {
  getAccount = async (reset = false): Promise<AccountItem> => {
    let random = ''
    if (reset) random = Math.random() + ''
    const resp = await this.get<[AccountItem]>(`accounts.json${reset ? `?random=${random}` : ''}`)
    return resp.data[0]
  }
  async updateAccount(accountUpdate: string): Promise<AccountUpdateWithUpdatedTime> {
    const resp = await this.put<AccountUpdateWithUpdatedTime, string>(
      'accounts.json',
      accountUpdate,
    )
    return resp.data
  }
  async createAccount(accountCreate: AccountCreate): Promise<AxiosResponse<AccountCreateResponse>> {
    const res = await this.post<AccountCreateResponse, AccountCreate>('users.json', accountCreate)
    return res
  }
}
