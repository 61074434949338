import { createApp, markRaw } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/styles/global.css'
import 'vue3-loading-overlay'
import { IconPack, library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { datadogRum } from '@datadog/browser-rum'
import { dataDogConfig } from './aws-exports'
import { createPinia } from 'pinia'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
// Import stylesheet
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

const pinia = createPinia()
library.add(fas as IconPack)
const app = createApp(App)

// init datadog rum
if (dataDogConfig) {
  datadogRum.init(dataDogConfig)
  datadogRum.startSessionReplayRecording()
}
app.component('FontAwesomeIcon', FontAwesomeIcon)
app.use(BootstrapVue)
app.use(BootstrapVueIcons)
pinia.use(({ store }) => {
  store.router = markRaw(router)
})
app.use(router)
router.isReady().then(() => {
  app.mount('#app')
})
app.use(pinia)
