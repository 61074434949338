<template>
  <div v-show="status.open" class="dialog-fluid">
    <div class="dialog-content">
      <header class="dialog-header">
        <h3>Network Error</h3>
      </header>
      <div class="dialog-body">
        <h5>
          インターネットに接続できません。<br />
          ネットワークに問題がないか確認して下さい。
        </h5>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
const status = defineProps({
  open: { type: Boolean, required: true, default: false },
})
</script>
<style scoped>
.dialog-content {
  width: 500px;
  padding: 0;
  border-radius: 5px;
}
.dialog-header {
  background-color: #17a2b8;
  padding: 15px;
}
h3 {
  color: #fff;
}
.dialog-body {
  padding: 15px;
}
h5 {
  line-height: 1.5;
}
</style>
