import { defineStore } from 'pinia'

export const appStore = defineStore('app', {
  state: () => ({
    renderComplete: false,
    isServerErrorDialog: false,
    isServerErrorInline: false,
  }),

  getters: {},
  actions: {
    setRenderComplete(payload: any): void {
      this.renderComplete = payload
    },
    setServerErrorDialog(payload: any): void {
      this.isServerErrorDialog = payload
    },
    setServerErrorInline(payload: any): void {
      this.isServerErrorInline = payload
    },
  },
})
