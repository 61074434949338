<template>
  <div id="app">
    <Navbar :user-level-infor="userLevel" />
    <div class="main">
      <router-view class="router-view col-12" />
    </div>
    <AlertNetwork :open="open" />
    <PopupServerError :open="isServerErrorDialog" @on-close="clearState" />
  </div>
</template>

<script lang="ts" setup>
import Navbar from './components/Navbar.vue'
import { Auth } from '@/apis/mylixil_auth'
import eventBus, { EVENT } from './EventBus'
import AlertNetwork from '../src/components/AlertNetworlError.vue'
import PopupServerError from '../src/components/PopupServerError.vue'
import { apiSetting } from './aws-exports'
import axios from 'axios'
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { userStoreAccount } from './store/modules/account'
import { appStore } from './store/modules/app'
import { storeToRefs } from 'pinia'
const { signOut, userLevel } = userStoreAccount()
const store = appStore()
const { isServerErrorDialog } = storeToRefs(store)
const { setServerErrorDialog } = store
const router = useRouter()
const route = useRoute()

const open = ref(false)
const clearState = () => {
  if (route.path.includes('/signin')) {
    Auth.logout()
  }
  setServerErrorDialog(false)
}
onMounted(async () => {
  const urlReload = apiSetting.internal_base + '/health'
  try {
    const response = await axios.get(urlReload)
    if (response.status === 200 && route.path.includes('/sorry')) {
      router.push('/signin')
    }
  } catch (error: any) {
    const code = error?.response?.status
    if (code === 307) {
      router.push({ name: 'maintenance' })
    } else {
      router.push({ name: 'sorry' })
    }
  }
  eventBus.$on(EVENT.SIGNOUT, () => {
    signOut()
    Auth.logout()
  })
  window.addEventListener('offline', () => {
    open.value = true
  })
  window.addEventListener('online', () => {
    open.value = false
  })
})
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main {
  width: 100%;
  flex: 1;
  display: flex;
  align-content: center;
}

.router-view {
  margin-left: auto;
  margin-right: auto;
}
.modal-body {
  font-size: 14px;
}

:deep() .vld-overlay {
  z-index: 100;
  position: absolute;
  width: 100%;
  height: 100%;
  background: gray;
  display: flex !important;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.spinner-border {
  width: 64px !important;
  height: 64px !important;
}
</style>
