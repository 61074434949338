<template>
  <div v-show="props.open" class="dialog-fluid">
    <div class="dialog-content">
      <header class="dialog-header">
        <h3>お知らせ</h3>
      </header>
      <div class="dialog-body">
        <h5>
          情報を取得できませんでした。<br />
          再度実行して下さい。
        </h5>
      </div>
      <footer class="dialog-footer">
        <button class="btn btn--update" @click="closePopup">OK</button>
      </footer>
    </div>
  </div>
</template>
<script lang="ts" setup>
const props = defineProps({
  open: { type: Boolean, required: true, default: false },
})

const emit = defineEmits(['onClose'])

const closePopup = () => {
  emit('onClose')
}
</script>
<style scoped>
.dialog-content {
  width: 500px !important;
  padding: 0 !important;
  border-radius: 5px !important;
}
.dialog-header {
  background-color: #17a2b8 !important;
  padding: 15px !important;
}
h3 {
  color: #fff !important;
}
.dialog-body {
  padding: 15px !important;
}
h5 {
  line-height: 1.5 !important;
  margin-top: 0.5rem;
}

.dialog-footer {
  padding: 0 15px 15px !important;
}
</style>
