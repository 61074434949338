export const awsmobile: { [key: string]: string } = {
  aws_project_region: 'ap-northeast-1',
  JANRAIN_CLIENT_ID: '984ac9fe-9c19-425f-b4d1-f88222acf268',
  JANRAIN_CUSTOMER_ID: '027e8254-2902-3341-9c3c-637841000000',
  MY_LIXIL_API_DOMAIN: 'test-my.api.lixil.co.jp',
  MY_LIXIL_SCREEN_DOMAIN: 'test-my.lixil.co.jp',
  JANRAIN_DOMAIN: 'v1.api.tk.janrain.com',
};

export const apiSetting: { [key: string]: string } = {
  internal_base: 'https://api.stg.greensheep.dev-lixil.com/v2',
};

export const dataDogConfig: any = null;
