import emitter from 'tiny-emitter/instance'
const eventBus = {
  $on: (...args: any) => emitter.on(...args),
  $once: (...args: any) => emitter.once(...args),
  $off: (...args: any) => emitter.off(...args),
  $emit: (...args: any) => emitter.emit(...args),
}

export default eventBus

export enum EVENT {
  REGISTER = 'register_bus',
  DESTROY = 'destroy_bus',
  SIGNOUT = 'signout',
  NETWORK_OFFLINE = 'network_offline',
  NETWORK_ONINE = 'network_online',
}
