<template>
  <b-navbar
    v-if="!checkRouterName"
    style="min-width: 1230px; padding: 0px"
    toggleable="lg"
    type="dark"
    variant="info"
  >
    <b-navbar-brand>
      <router-link style="text-decoration: none" to="/menu">
        <span class="nav_item nav-title">Toilet Portal</span>
      </router-link>
    </b-navbar-brand>
    <b-navbar-toggle
      style="margin-right: auto; border: 1px solid whitesmoke; outline: none"
      target="nav-collapse"
    ></b-navbar-toggle>
    <b-collapse
      v-if="route.name !== 'signin' && route.name !== 'pageNotFound'"
      id="nav-collapse"
      is-nav
    >
      <b-navbar-nav v-if="route.name !== 'menu'">
        <b-nav-item class="mr-auto mt-2">
          <router-link style="text-decoration: none" to="/menu">
            <span :class="{ nav_item: true }">Menu</span>
          </router-link>
        </b-nav-item>
        <b-nav-item class="mr-auto mt-2">
          <router-link style="text-decoration: none" to="/places">
            <span
              :class="{
                active: ['places', 'create_place', 'update_place'].includes(route.name),
                nav_item: true,
              }"
              >Place</span
            >
          </router-link>
        </b-nav-item>
        <b-nav-item class="mr-auto mt-2">
          <router-link style="text-decoration: none" to="/devices">
            <span
              :class="{
                active: ['devices', 'create_device', 'update_device'].includes(route.name),
                nav_item: true,
              }"
              >Device</span
            >
          </router-link>
        </b-nav-item>
        <b-nav-item class="mr-auto mt-2">
          <router-link style="text-decoration: none" to="/connect">
            <span
              :class="{
                active: ['gateway'].includes(route.name),
                nav_item: true,
              }"
              >Gateway＆router＆IoTunit</span
            >
          </router-link>
        </b-nav-item>
        <b-nav-item class="mr-auto mt-2">
          <router-link style="text-decoration: none" to="/item_groups">
            <span :class="[['item_groups'].includes(route.name) ? 'active' : '', 'nav_item']"
              >ItemGroup</span
            >
          </router-link>
        </b-nav-item>
        <b-nav-item v-if="isRoleA" class="mr-auto mt-2">
          <router-link style="text-decoration: none" to="/users">
            <span :class="[['users'].includes(route.name) ? 'active' : '', 'nav_item']"
              >User Management</span
            >
          </router-link>
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item class="mr-auto mt-2 icon">
          <span :class="{ nav_item: true }" class="dl-flex" @click="updateprofile()">
            <img src="@/assets/icons/account-circle.svg" />
            <span
              v-if="userStore.name?.familyName && userStore.name?.givenName"
              style="align-self: center; max-width: 200px"
              class="text-dot"
              >{{ userStore.name?.familyName + ' ' + userStore.name?.givenName }}</span
            >
          </span>
        </b-nav-item>
        <b-nav-item class="mr-auto">
          <button class="logout-btn" @click="logout()">Logout</button>
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>
<script lang="ts" setup>
import { Auth } from '@/apis/mylixil_auth'
import { UserLevel } from '@/utils'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { userStoreAccount } from '@/store/modules/account'
const route = useRoute()
const userStore = userStoreAccount()

const isRoleA = computed(() => {
  return userStore.userLevel === UserLevel.SYSTEM_ADMIN
})

const checkRouterName = computed(() => {
  return route.path.includes('/sorry')
})
const logout = () => {
  Auth.logout()
}
const updateprofile = () => {
  Auth.updateprofile()
}
</script>
<style scoped>
.nav_item {
  margin-left: 22px;
  color: white;
  display: inline-block;
}
.nav-title {
  font-size: 2rem;
}

.active {
  color: rgb(255, 255, 255);
  font-weight: bold;
}
.logout-btn {
  border: none;
  padding: 10px 30px;
  text-align: center;
  background-color: rgb(231, 137, 13);
  color: white;
  border-radius: 5px;
  margin: 5px 10px 0 0;
}
.icon {
  padding-right: 70px;
}
</style>
